import { createInertiaApp } from '@inertiajs/react';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { LaravelReactI18nProvider } from 'laravel-react-i18n';
import { ThemeProvider } from 'styled-components';
import { themeConfig } from './configs/ThemeConfig';
import GlobalStyle from './components/GlobalStyle';
import Preloader from './components/Preloader';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import '../css/app.css';
import { PopupProvider } from '@/hooks/PopupHook';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://1021609746494a9b8d1cfee5b8c12196@o1165738.ingest.sentry.io/4504649561276416',
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.0,
    });
}

createInertiaApp({
    title: (title) => title,
    resolve: (name) => resolvePageComponent(`./pages/${name}.jsx`, import.meta.glob('./pages/**/*.jsx')),
    progress: { delay: 250, color: '#00FFA3', showSpinner: false },
    setup({ el, App, props }) {
        const locale = props.initialPage.props.locale || 'nl';
        const files = import.meta.glob('/lang/*.json');

        createRoot(el).render(
            <LaravelReactI18nProvider locale={locale} fallbackLocale={'en'} files={files} hideFirstLoad={false}>
                <ThemeProvider theme={themeConfig}>
                    <PopupProvider>
                        <GlobalStyle />
                        <Preloader />
                        <App {...props} />
                    </PopupProvider>
                </ThemeProvider>
            </LaravelReactI18nProvider>,
        );
    },
});
