import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'Poppins', sans-serif;
    }

    body {
        margin-bottom: 0 !important;
        color: ${({ theme: { colors } }) => colors.text.base};
        max-width: 100%;
    }

    a {
        text-decoration: none;
        color: unset;
        -webkit-user-drag: none;
    }

    table {
        width: 100%;
    }

    th, td {
        padding: 16px 0;
    }

    th {
        text-align: left;
    }

    ::-webkit-scrollbar {
        display: none;
        width: 12px;
    }

    ::-webkit-scrollbar-track {
        border-right: 10px solid transparent;
        background: ${({ theme: { colors } }) => colors.grey[1]};
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: ${({ theme: { colors } }) => colors.grey[4]};
    }
`;

export default GlobalStyle;
