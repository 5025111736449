import { createContext, useContext, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

export const PopupContext = createContext();

export const usePopup = () => {
    const context = useContext(PopupContext);

    return context;
};

export const PopupProvider = ({ children }) => {
    const [activePopups, setActivePopups] = useState([]);

    const open = (Component, properties = {}) => {
        let newActivePopups = [...activePopups];

        newActivePopups.push({ Component, properties });
        setActivePopups(newActivePopups);
    };

    const close = (index) => {
        let newActivePopups = [...activePopups];

        newActivePopups.splice(index, 1);
        setActivePopups(newActivePopups);
    };

    return (
        <PopupContext.Provider value={{ open }}>
            <AnimatePresence>
                {activePopups.map((activePopup, index) => {
                    const { Component, properties } = activePopup;

                    return <Component key={`popup-${index}`} {...properties} onClose={() => close(index)} />;
                })}
            </AnimatePresence>

            {children}
        </PopupContext.Provider>
    );
};
