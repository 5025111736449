import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const Preloader = () => {
    const componentRef = useRef();
    const logoRef = useRef();

    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        gsap.timeline()
            .to(logoRef.current, { scale: 0, autoAlpha: 0, duration: 0.5, delay: 0.5 })
            .to(componentRef.current, { autoAlpha: 0, duration: 0.4, onComplete: () => (document.body.style.overflowY = 'auto') });
    }, []);

    return (
        <StyledPreloader ref={componentRef}>
            <StyledPatrone src={'/assets/images/patrone.png'} />

            <StyledLogo ref={logoRef} src={'/assets/images/icons/light.svg'} alt={'Monvy'} />
        </StyledPreloader>
    );
};

const StyledPreloader = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background: linear-gradient(180deg, #145782 0%, #d1d1d1 0%, #0d4c74 0%, #15649f 100%);
    z-index: 1000;
`;

const StyledPatrone = styled.img`
    position: absolute;
    top: -5vw;
    left: -5vw;
    z-index: 0;
    width: 100%;
    aspect-ratio: 1;
`;

const StyledLogo = styled.img`
    height: 80px;
`;

export default Preloader;
