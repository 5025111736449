import { variant } from 'styled-system';

const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};

const colors = {
    background: {
        base: '#FBFBFB',
        variant: '#FFFFFF',
        contrast: '#404040',
    },
    text: {
        base: '#404040',
        variant: '#7F7F7F',
    },
    primary: {
        base: '#005F9C',
        variant: '#0067AA',
        contrast: '#FFFFFF',
    },
    cloud: '#F5F9FB',
    acid: '#00FFA3',
    orange: '#FF781B',
    black: '#292929',
    success: '#70CD61',
    warning: '#FF9900',
    error: '#B32121',
    grey: ['#F5F5F5', '#E8E8E8', '#DCDCDC', '#D3D3D3', '#C8C8C8', '#BEBEBE', '#B0B0B0', '#A8A8A8', '#989898', '#888888'],
    state: {
        grey: '#E4E4E4',
        red: '#F43A3A',
        orange: '#BD2B71',
        yellow: '#F1F43A',
        green: '#2CEA33',
        blue: '#B5D2FD',
        purple: '#F43AC0',
        bordeaux: '#BD2B71',
    },
    offer: {
        concept: '#F2F2F2',
        outstanding: '#C8DEFD',
        accepted: '#B5FDE3',
        declined: '#FFD8D8',
        expired: '#FFD8D8',
        invoiced: '#C8DEFD',
    },
    invoice: {
        concept: '#F2F2F2',
        outstanding: '#C8DEFD',
        expired: '#FFD8D8',
        paid: '#B5FDE3',
        factored: '#B5FDE3',
        first_reminder: '#FFF4CE',
        first_warning: '#FFE9CE',
        notice_of_default: '#F8C7B7',
        collection: '#FFCECE',
        legal_partner: '#FFCECE',
    },
    performance: {
        concept: '#F2F2F2',
        sent: '#C8DEFD',
        accepted: '#B5FDE3',
        declined: '#FFD8D8',
        invoiced: '#B5FDE3',
    },
    scores: {
        a: '#70CD61',
        b: '#8FEA81',
        c: '#FF9900',
        d: '#F43C3A',
        e: '#B32121',
    },
    backdrop: 'rgba(0, 0, 0, .6)',
};

const fontSizes = {
    xs: 12,
    sm: 14,
    md: 18,
    lg: 20,
    xl: 24,
    xxl: 28,
};

const fontWeights = {
    thin: 100,
    light: 300,
    regular: 400,
    bold: 600,
    black: 900,
};

const buttonStyles = {
    primary: {
        backgroundColor: colors.primary.base,
        color: colors.primary.contrast,
    },
    black: {
        backgroundColor: colors.black,
        color: colors.black.contrast,
    },
    grey: {
        backgroundColor: colors.grey[1],
        color: colors.text.base,
        '&:hover': {
            backgroundColor: colors.grey[2],
        },
    },
    orange: {
        backgroundColor: colors.orange,
        color: colors.orange.contrast,
    },
    transparent: {
        backgroundColor: 'transparent',
    },
};

const buttonSizes = {
    small: {
        height: 40,
        fontSize: fontSizes.xs,
        fontWeight: fontWeights.bold,
    },
    medium: {
        height: 48,
        fontSize: fontSizes.sm,
        fontWeight: fontWeights.bold,
    },
    large: {
        height: 56,
        fontSize: fontSizes.sm,
        fontWeight: fontWeights.bold,
    },
};

const shadows = {
    sidebar: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
    panel: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
    dropdown: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
};

const space = [];

const radii = [];

const themeConfig = { colors, breakpoints, space, radii, fontSizes, fontWeights, buttons: buttonStyles, buttonSizes, shadows };

const buttonSize = variant({
    prop: 'size',
    key: 'buttonSizes',
});

export { themeConfig, buttonSize, colors };
